import React from "react";
// local import
import * as classes from "../../styles/newsroom.module.scss";

const Card = ({ link, imgUrl, heading, date }) => {
  return (
    <a href={link} target="_blank" rel="noreferrer">
      <div className={classes.card}>
        <div>
          <img
            className={classes.vertical_img}
            src={imgUrl}
            alt="newsroom"
          ></img>
        </div>
        <div className={classes.content_box}>
          <h3 className={classes.content_box__heading}>{heading}</h3>
          <p className={classes.content_box__date}>{date}</p>
        </div>
      </div>
    </a>
  );
};

export default Card;
