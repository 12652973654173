import React from "react";
// local import
import * as classes from "../../styles/newsroom.module.scss";

const HorizontalCard = ({ link, imgUrl, heading, date, subheading }) => {
  return (
    <a href={link} target="_blank" rel="noreferrer">
      <div className={classes.horizontal_card}>
        <div className={classes.img_box}>
          <img className={classes.wide_img} src={imgUrl} alt="newsroom"></img>
        </div>
        <div className={classes.content_box}>
          <h3 className={classes.content_box__heading}>{heading}</h3>
          <p className={classes.content_box__date}>{date}</p>
          <p className={classes.content_box__subheading}>{subheading}</p>
        </div>
      </div>
    </a>
  );
};

export default HorizontalCard;
