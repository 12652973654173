// extracted by mini-css-extract-plugin
export var card = "newsroom-module--card--MRsj-";
export var container = "newsroom-module--container--VdWwM";
export var content_box = "newsroom-module--content_box--HgUQn";
export var content_box__date = "newsroom-module--content_box__date--IMbBy";
export var content_box__heading = "newsroom-module--content_box__heading--Vs8UC";
export var content_box__subheading = "newsroom-module--content_box__subheading--COexB";
export var heading = "newsroom-module--heading--ayr5s";
export var horizontal_card = "newsroom-module--horizontal_card--TaFEU";
export var img_box = "newsroom-module--img_box---O21l";
export var newsroom = "newsroom-module--newsroom--vWwbK";
export var newsroom__cards_row = "newsroom-module--newsroom__cards_row--83khR";
export var subheading = "newsroom-module--subheading--uB-Tr";
export var vertical_img = "newsroom-module--vertical_img--0CFEo";
export var wide_img = "newsroom-module--wide_img--tiGIF";