import React from "react";
import { Col, Container, Row } from "react-bootstrap";
// local import
import Layout from "../../components/Common/Layout";
import Seo from "../../components/Common/Seo";
import * as classes from "../../styles//newsroom.module.scss";
import HorizontalCard from "../../components/NewsRoom/HorizontalCard";

import techCrunchNews from "../../assets/images/homepage/techCrunchNews.png";
import cnbctvNews from "../../assets/images/homepage/cnbctvNews.png";
import forbesNews from "../../assets/images/homepage/forbesNews.png";
import nasdaqNews from "../../assets/images/homepage/nasdaqNews.png";
import Card from "../../components/NewsRoom/Card";

const Newsroom = () => {
  return (
    <Layout>
      <Seo
        title="Lucidity in the News"
        description="The latest news and mentions about Lucidity, our partners, and the latest innovations in “NoOps” cloud experience."
        canonicalUrl="https://www.lucidity.cloud/newsroom"
      />
      <section className={classes.newsroom}>
        <Container className={classes.container}>
          <Row className={classes.newsroom__cards_row}>
            <Col sm="12">
              <h1 className={classes.heading}>Lucidity in the news</h1>
              <p className={classes.subheading}>
                The latest news and mentions about Lucidity, our partners,{" "}
                <br></br>and the latest innovations in “NoOps” cloud experience.
              </p>
            </Col>
            <Col sm="12">
              <HorizontalCard
                link="https://techcrunch.com/2022/09/07/lucidity-keeps-cloud-block-storage-neat-tidy-and-cost-efficient"
                imgUrl={techCrunchNews}
                heading="Lucidity keeps cloud block storage neat, tidy, and cost-efficient"
                date="Sept 8, 2022"
                subheading="TechCrunch captures interesting facets in today’s cloud space, 
							and how firms are already leveraging Lucidity to adopt the vision of “NoOps for cloud storage“"
              />
            </Col>
          </Row>
          <Row className={classes.newsroom__cards_row}>
            <Col sm="12" md="6" lg="6">
              <Card
                link="https://www.forbes.com/sites/davidprosser/2022/09/08/realising-the-potential-of-cloud-computing-with-lucidity/?sh=669c37003bf2"
                imgUrl={forbesNews}
                heading="Realizing The Potential Of Cloud With Lucidity"
                date="Sept 8, 2022"
              />
            </Col>
            <Col sm="12" md="6" lg="6">
              <Card
                link="https://www.linkedin.com/feed/update/urn:li:activity:6973946945656549376"
                imgUrl={nasdaqNews}
                heading="Lucidity goes live in Times Square"
                date="Sept 9, 2022"
              />
            </Col>
          </Row>
          <Row className={classes.newsroom__cards_row}>
            <Col sm="12">
              <HorizontalCard
                link="https://www.cnbctv18.com/videos/startup/lucidity-to-utilise-53-million-fundraise-for-product-development-business-expansion-14717191.htm"
                imgUrl={cnbctvNews}
                heading="Lucidity just got covered in Young Turks"
                date="Sept 13, 2022"
                subheading="Watch here as our co-founder, Nitin Bhadauria discusses today's industry state, Lucidity, 
							our future expansion plans, and more, in India's longest-running startups show, Young Turks"
              />
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default Newsroom;
